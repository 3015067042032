import { Button, Container, rem, Text } from "@mantine/core";

/**
 * Replay button container component
 * Replay the game
 * @param {*} param0
 * @returns
 */
const ReplayButtonContainer = ({ isFounder, onClick }) => {
	return (
		<Container
			mt={20}
			p="sm"
			bg="var(--mantine-color-dark-6)"
			shadow="xl"
			radius="xl"
			style={{ width: "100%", borderRadius: rem(20) }}
		>
			{isFounder() && (
				<Button
					variant="filled"
					radius="lg"
					size="sm"
					styles={{
						root: {
							width: "100%",
							height: rem(48)
						}
					}}
					color="indigo"
					onClick={onClick}
				>
					Relancer une partie
				</Button>
			)}
			{!isFounder() && (
				<Text size="sm" align="center" mt={20} style={{ fontWeight: 500 }}>
					En attente du chef de la partie ...
				</Text>
			)}
		</Container>
	);
};

export default ReplayButtonContainer;
