import './flash.style.css';
import { ActionIcon, Button, Card, Container, Divider, Flex, Group, Input, Text, Title, Tooltip } from '@mantine/core';
import {
  IconAlertTriangle,
  IconChevronsRight,
  IconClockHour4,
  IconFolder,
  IconHandStop,
  IconMedal,
} from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import lod_ from 'lodash';
import { socket } from 'store/middleware/wsMiddleware';

const HistoryComponent = ({ reportWord, history, themes }) => {
  const [isReported, setIsReported] = useState(false);

  if (history?.type === 'skip') {
    return null;
  }

  const correctTheme = themes.find((theme) => theme.theme === history.theme);

  return (
    <Card bg="var(--mantine-color-dark-5)" p="xs" radius="md" mb="xs">
      <Flex align="center">
        <Flex direction="column" flex="1">
          <Text fw={800} size="sm" color="var(--mantine-color-dark-2)">
            {correctTheme.label}
          </Text>
          <Text fw={600}>{history.word}</Text>
        </Flex>
        <Flex justify="space-between" flex="3">
          <Text c={history.score > 0 ? 'var(--mantine-color-green-5)' : 'var(--mantine-color-red-5)'}>
            {history.score > 0 ? '+' : ''}
            {history.score}
          </Text>
          {history.score <= 0 && (
            <Tooltip
              pos="top"
              label={isReported ? 'Mot déjà signalé' : 'Signaler un problème avec ce mot'}
              withArrow
              transition="fade"
              bg="var(--mantine-color-red-5)"
            >
              <ActionIcon
                disabled={isReported}
                variant="transparent"
                color="red"
                aria-label="Signaler"
                onClick={() => {
                  reportWord(history.word);
                  setIsReported(true);
                }}
              >
                <IconAlertTriangle style={{ width: '70%', height: '70%' }} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          )}
        </Flex>
      </Flex>
    </Card>
  );
};

const FlashGameComponent = ({ uuid, game }) => {
  const computeDuration = () => {
    let start = game.startTS;
    let now = new Date().getTime();
    let duration = now - start;
    let humanDisplay = new Date(duration).toISOString().substr(11, 8);
    return humanDisplay;
  };

  const [userWord, setUserWord] = useState('');
  const [actualTheme, setActualTheme] = useState(game.themes[game.wordIndex - 1]);
  const [duration, setDuration] = useState(computeDuration());

  const [inputValid, setInputValid] = useState(null);

  const clearInput = () => {
    setUserWord('');
  };

  const inputAnimation = (valid) => {
    setInputValid(valid);
    setTimeout(() => {
      setInputValid(null);
    }, 1000);
  };

  const skipTheme = () => {
    clearInput();
    inputAnimation('error');
    socket.emit('userSkipFlashThemeWord', { uuid, theme: actualTheme.theme });
  };

  const validateTheme = () => {
    if (userWord === '') {
      return;
    }

    inputAnimation('success');
    clearInput();

    socket.emit(
      'validateFlashThemeWord',
      {
        uuid,
        word: userWord,
        theme: actualTheme.theme,
        letter: actualTheme.letter,
      },
      (exist) => {
        if (exist) {
          inputAnimation('success');
        } else {
          inputAnimation('error');
        }
      }
    );
  };

  const reportWord = (word) => {
    socket.emit('reportErrorWord', {
      uuid,
      word: word,
      theme: actualTheme.theme,
      letter: actualTheme.letter,
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setDuration(computeDuration());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setActualTheme(game.themes[game.wordIndex - 1]);
  }, [game]);

  return (
    <Container
      m={0}
      p={0}
      fluid
      flex="1"
      display="flex"
      style={{
        flexDirection: 'column',
      }}
    >
      {/* Line header */}
      <Flex>
        <Card flex="1" radius="md" bg="var(--mantine-color-dark-5)">
          <Flex align="center" justify="space-evenly">
            {/* Temps écoulé */}
            <Flex direction="column" m={10}>
              <Group align="center" gap={0}>
                <IconClockHour4
                  style={{
                    color: `var(--mantine-color-indigo-6)`,
                  }}
                  stroke={1.5}
                />
                <Text ml={5} fw={600}>
                  Temps écoulé
                </Text>
              </Group>
              <Text align="center" fw={900}>
                {duration}
              </Text>
            </Flex>
            {/* Themes */}
            <Flex direction="column" m={10}>
              <Group align="center" gap={0}>
                <IconFolder
                  style={{
                    color: `var(--mantine-color-green-6)`,
                  }}
                  stroke={1.5}
                />
                <Text ml={5} fw={600}>
                  Thèmes
                </Text>
              </Group>
              <Text align="center" fw={900}>
                {`${game.wordIndex}/10`}
              </Text>
            </Flex>
            {/* Points */}
            <Flex direction="column" m={10}>
              <Group align="center" gap={0}>
                <IconMedal
                  style={{
                    color: `var(--mantine-color-yellow-6)`,
                  }}
                  stroke={1.5}
                />
                <Text ml={5} fw={600}>
                  Points
                </Text>
              </Group>
              <Text align="center" fw={900}>
                {game.score}
              </Text>
            </Flex>
          </Flex>
        </Card>
      </Flex>
      {/* Line theme */}
      <Flex mt="md">
        <Card flex="6" radius="md" bg="var(--mantine-color-dark-6)" withBorder c="var(--mantine-color-light-8)">
          {/* <Title order={2}>
            {`${actualTheme.emoji} ${actualTheme.label}`} en{" "}
            {`${actualTheme.letter}`}
          </Title> */}
          <Flex align="end">
            <Title order={2}>{`${actualTheme.emoji} ${actualTheme.label}`}</Title>
            <Text size="lg" ml="xs" mr="xs">
              en
            </Text>
            <Title order={2}>{`${actualTheme.letter}`}</Title>
          </Flex>
        </Card>

        {/* <Card
          flex="1"
          ml="md"
          radius="md"
          bg="var(--mantine-color-dark-6)"
          withBorder
          c="var(--mantine-color-light-8)"
        >
          <Title order={3} align="center">{`${actualTheme.letter}`}</Title>
        </Card> */}
      </Flex>
      {/*  */}
      <Flex mt="xl" flex="1" align="center" direction="column">
        {/* Inputs */}
        <Container
          flex="1"
          m={0}
          p={0}
          fluid
          display="flex"
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '50vh',
            width: '65%',
          }}
        >
          <Flex direction="column" align="center" style={{ width: '100%' }}>
            {/* Input word */}
            <Input
              // flex={1}
              style={{ width: '100%' }}
              placeholder={`${actualTheme.letter} ...`}
              radius="md"
              size="xl"
              classNames={{
                input: `input-flash-game ${inputValid === 'success' ? 'input-flash-game-success' : ''}`,
              }}
              value={userWord}
              onChange={(e) => setUserWord(e.currentTarget.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  validateTheme();
                }

                if (e.key === 'Escape') {
                  skipTheme();
                }
              }}
              error={inputValid === 'error' ? true : false}
            ></Input>
            {/* Actions button skip / validate */}
            <Flex mt="md" style={{ width: '100%' }}>
              <Button
                radius="md"
                size="xl"
                color="var(--mantine-color-grape-8)"
                rightSection={<IconChevronsRight />}
                onClick={skipTheme}
              >
                Passer
              </Button>
              <Button
                radius="md"
                size="xl"
                color="indigo"
                ml="md"
                flex={1}
                rightSection={<IconHandStop />}
                onClick={validateTheme}
              >
                Valider
              </Button>
            </Flex>
          </Flex>
        </Container>
        {/* History */}
        <Card
          // flex="2"
          radius="md"
          bg="var(--mantine-color-body)"
          withBorder
          c="var(--mantine-color-light-8)"
          style={{
            width: '100%',
          }}
        >
          <Flex direction="column">
            <Text fw={800}>Historique</Text>
            <Divider mt="xs" mb="xs" />
            <Container
              flex="1"
              m={0}
              p={0}
              fluid
              style={{
                maxHeight: '65vh',
                overflow: 'auto',
              }}
            >
              {lod_
                .cloneDeep(game?.themes ?? [])
                .reverse()
                .map((theme, index) => (
                  <HistoryTheme
                    key={index}
                    history={game?.history[theme.theme] ?? []}
                    reportWord={reportWord}
                    themes={game?.themes}
                  />
                ))}
            </Container>
          </Flex>
        </Card>
      </Flex>
    </Container>
  );
};

const HistoryTheme = ({ history, reportWord, themes }) => {
  return (
    <Container>
      {lod_
        .cloneDeep(history)
        .reverse()
        .map((history, index) => (
          <HistoryComponent key={index} reportWord={reportWord} history={history} themes={themes} />
        ))}
    </Container>
  );
};

export default FlashGameComponent;
