import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { selectUser } from "store/reducers/userReducer";
import lod_ from "lodash";

/**
 * Middleware to check if user is logged in
 * @param {*} param0
 * @returns
 */
const ProtectedRoute = ({ children }) => {
  let user = useSelector(selectUser);

  if (!lod_.isEmpty(user)) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

export default ProtectedRoute;
