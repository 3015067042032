import { useSelector } from "react-redux";
import PrivateRoom from "./Private";
import ClassicScreenInGame from "pages/games/classic/classic.game";
import ClassicScreenReviewRound from "pages/games/classic/classic.review_round";
import ClassicScreenResults from "pages/games/classic/classic.results";

const ROOM_STATUS = {
	SEARCHING: "searching",
	WAITING: "waiting",
	IN_GAME: "in_game",
	IN_GAME_REVIEW: "review_round",
	IN_GAME_RESULTS: "results",
	FINISHED: "finished"
};

/**
 * Choose screen for classic game
 * @param {*} room
 * @returns
 */
const getPrivateRoomScreen = room => {
	switch (room.status) {
		// Waiting screen
		case ROOM_STATUS.SEARCHING:
		case ROOM_STATUS.WAITING:
			return <PrivateRoom />;
		// In game screen
		case ROOM_STATUS.IN_GAME:
			return <ClassicScreenInGame />;
		// Review round screen
		case ROOM_STATUS.IN_GAME_REVIEW:
			return <ClassicScreenReviewRound />;
		// Result screen
		case ROOM_STATUS.IN_GAME_RESULTS:
		case ROOM_STATUS.FINISHED:
			return <ClassicScreenResults />;
		default:
			return <h1>404 :/</h1>;
	}
};

export default function WaitingRoom() {
	const room = useSelector(state => state.room);

	switch (room.gameMode) {
		case "normal":
			return <h1>normal</h1>;
		case "ranked":
			return <h1>ranked</h1>;
		default:
			return getPrivateRoomScreen(room);
	}
}
