/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import lod_ from "lodash";
import WaitingMenu from "./Menu";
import WaitingRoom from "./Room";

export default function Waiting() {
  const room = useSelector((state) => state.room);

  if (lod_.isEmpty(room)) {
    return <WaitingMenu />;
  }

  return <WaitingRoom />;
}
