import ReactGA from "react-ga4";
import { Button, rem, Tooltip } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { IconCopy, IconCheck } from "@tabler/icons-react";

export function ButtonCopy({ url }) {
	const clipboard = useClipboard();
	return (
		<Tooltip
			label="Lien copié !"
			offset={5}
			position="bottom"
			radius="xl"
			transitionProps={{ duration: 100, transition: "slide-down" }}
			opened={clipboard.copied}
		>
			<Button
				variant="light"
				rightSection={
					clipboard.copied ? (
						<IconCheck style={{ width: rem(20), height: rem(20) }} stroke={1.5} />
					) : (
						<IconCopy style={{ width: rem(20), height: rem(20) }} stroke={1.5} />
					)
				}
				radius="xl"
				size="md"
				styles={{
					root: { paddingRight: rem(14), height: rem(48) },
					section: { marginLeft: rem(22) }
				}}
				onClick={() => {
					ReactGA.event({
						category: "Interaction",
						action: "Copier le lien d'invitation partie publique",
						label: "Copier le lien d'invitation partie publique"
					});

					clipboard.copy(url);
				}}
			>
				Copier le lien d'invitation
			</Button>
		</Tooltip>
	);
}
