import ReactGA from "react-ga4";
import { Button, Container, Flex, rem, Text } from "@mantine/core";
import { socket } from "store/middleware/wsMiddleware";

/**
 * Component to display the start game container button
 * @param {object} props.room - Room data
 * @param {function} props.isFounder - Function to check if user is the founder
 * @returns
 */
const StartGameContainer = ({ room, isFounder }) => {
	/***
	 * Check if all users are ready
	 */
	const canUserStartGame = () => {
		let allUsersReady = room.users.every(u => u.ready);
		return allUsersReady;
	};

	const startGame = () => {
		ReactGA.event({
			category: "Interaction",
			action: "Lancement de la partie partie classique",
			label: "Lancement de la partie partie classique"
		});

		socket.emit("game_classic_start", {
			roomId: room.roomId
		});
	};

	return (
		<Container
			mt={20}
			p="sm"
			bg="var(--mantine-color-dark-6)"
			shadow="xl"
			radius="xl"
			style={{ width: "100%", borderRadius: rem(20) }}
		>
			<Flex direction="column" align="center" justify="center">
				<Text size="xs" align="center" style={{ fontWeight: 500 }}>
					Tous les joueurs doivent être prêts pour lancer la partie
				</Text>
				{isFounder() && (
					<Button
						variant="filled"
						radius="xl"
						size="sm"
						mt={10}
						styles={{
							root: {
								width: "100%",
								height: rem(48)
							}
						}}
						color="blue"
						disabled={!canUserStartGame()}
						onClick={() => {
							startGame();
						}}
					>
						Commencer la partie
					</Button>
				)}
				{!isFounder() && (
					<Text size="sm" align="center" mt={20} style={{ fontWeight: 500 }}>
						En attente du chef de la partie ...
					</Text>
				)}
			</Flex>
		</Container>
	);
};

export default StartGameContainer;
