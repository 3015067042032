/* eslint-disable react-hooks/exhaustive-deps */
import "./style.css";
import lod_ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { wsEvent } from "store/actions/wsActions";
import { selectUser } from "store/reducers/userReducer";
import { socket } from "store/middleware/wsMiddleware";
import StopButtonContainer from "./components/StopButtonContainer";
import {
	Card,
	Container,
	Flex,
	Group,
	Input,
	Paper,
	Progress,
	Text,
	Title,
	rem
} from "@mantine/core";
import { IconAwardFilled } from "@tabler/icons-react";
import { LeaveButton } from "components/LeaveButton";
import UserCard from "components/UserCard";

/**
 * Classic game in game screen
 * @returns
 */
export default function ClassicScreenInGame() {
	const room = useSelector(state => state.room);
	const config = room?.config ?? {};

	const dispatch = useDispatch();
	const user = useSelector(selectUser);

	const enableTimer = Boolean(config.timer !== "none");
	const [timeLeft, setTimeLeft] = useState(config.timer);
	const [timeLeftPercentage, setTimeLeftPercentage] = useState(0);

	const [roundIsFinish, setRoundIsFinish] = useState(false);

	// User answers for all words
	const [answers, setAnswers] = useState({});

	// Use a ref to store the intervalId
	const intervalRef = useRef(null);

	const [sortedScoreUser, setSortedScoreUser] = useState([]);

	const getProgressBarColor = () => {
		if (roundIsFinish) {
			return "red";
		}
		if (timeLeftPercentage < 50) {
			return "indigo";
		}
		if (timeLeftPercentage < 75) {
			return "orange";
		}
		return "red";
	};

	/**
	 * Update the answer for a word
	 * @param {string} word - The word to update
	 * @param {string} value - The value to set
	 */
	const handleAnswerChange = (word, value) => {
		setAnswers({
			...answers,
			[word]: value
		});
	};

	/**
	 * User click on the stop button
	 */
	const handleStopButton = () => {
		socket.emit("game_classic_stop_round", {
			roomId: room.roomId
		});
	};

	const leaveRoomAction = (uuid = user.uuid) => {
		dispatch(
			wsEvent({
				event: "user_leave_room",
				payload: {
					roomId: room.roomId,
					userId: uuid
				}
			})
		);
	};

	const computeTimeLeft = () => {
		if (roundIsFinish) {
			return;
		}

		const roundStartTS = config.game.roundStartTS;
		const roundDurationInSeconds = config.timer;

		const now = Date.now();
		const timePassedInMS = now - roundStartTS;
		const timeLeftInMS = roundDurationInSeconds * 1000 - timePassedInMS;
		let timeLeft = Math.floor(timeLeftInMS / 1000);
		if (timeLeft < 0) timeLeft = 0;
		setTimeLeft(timeLeft === 0 ? "Terminé !" : `${timeLeft} secondes restantes ...`);

		// Compute the time on 100 to put it in the progress bar
		const timePassed = roundDurationInSeconds - timeLeft;
		const timePassedPercentage = (timePassed / roundDurationInSeconds) * 100;
		setTimeLeftPercentage(timePassedPercentage);

		// Clear interval if timeLeft is 0
		if (timeLeft === 0 && intervalRef.current) {
			clearInterval(intervalRef.current);
			intervalRef.current = null;
		}
	};

	const startTimer = () => {
		if (!enableTimer || intervalRef.current) return;

		const interval = setInterval(() => {
			computeTimeLeft();
		}, 1000);
		intervalRef.current = interval;
	};

	/**
	 * Event received from the back when the round is stopped.
	 * User sends his answers to the server
	 */
	const stopRound = () => {
		// Set round as finish to disable inputs / button
		setRoundIsFinish(true);
		setTimeLeft("Terminé !");
		// End the timer
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
			intervalRef.current = null;
		}
		// Send answers to the server
		socket.emit("game_classic_stop_round_user_datas", {
			roomId: room.roomId,
			userId: user.uuid,
			answers: answers
		});
	};

	// Websockets
	useEffect(() => {
		if (!socket) return;

		socket.on("game_classic_stop_round", stopRound);

		return () => {
			socket.off("game_classic_stop_round", stopRound);
		};
	}, [socket, answers]);

	// Effect to start the timer when roundStartTS changes
	useEffect(() => {
		startTimer();

		return () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
				intervalRef.current = null;
			}
		};
	}, [config.game.roundStartTS, enableTimer]); // Ensure proper dependency

	// Init answers with empty strings
	useEffect(() => {
		const answers = {};
		config.words.forEach(word => {
			answers[word] = "";
		});

		setAnswers(answers);
	}, [config.words]);

	useEffect(() => {
		const filteredScoreUser = [];
		const score = config.game.score;
		// Sort the score object by score
		for (const [key, value] of Object.entries(score)) {
			filteredScoreUser.push({ uuid: key, score: value });
		}
		filteredScoreUser.sort((a, b) => b.score - a.score);
		// Add every user to the filteredScoreUser array that are not in the score object
		room.users.forEach(user => {
			if (!filteredScoreUser.find(scoreUser => scoreUser.uuid === user.uuid)) {
				filteredScoreUser.push({ uuid: user.uuid, score: 0 });
			}
		});
		setSortedScoreUser(filteredScoreUser);
	}, []);

	if (lod_.isEmpty(answers)) {
		return null;
	}

	return (
		<Container
			m={0}
			p={0}
			fluid
			flex="1"
			style={{
				position: "relative",
				height: "calc(100vh - 2rem)",
				display: "flex",
				flexDirection: "column"
			}}
		>
			<Paper shadow="lg" radius="xl" p="md" bg="var(--mantine-color-dark-6)">
				<Flex direction="row" align="center" justify="space-between">
					<Flex flex={1} align="center">
						<Title
							order={3}
							style={{ marginLeft: rem(20), whiteSpace: "nowrap" }}
						>{`${config.game.currentRound} / ${config.rounds}`}</Title>
						{enableTimer && (
							<Flex
								direction="column"
								style={{
									marginLeft: rem(20),
									width: "100%"
								}}
							>
								<Text>{timeLeft}</Text>
								<Progress
									style={{
										width: "100%"
									}}
									color={getProgressBarColor()}
									value={timeLeftPercentage}
									striped={timeLeftPercentage < 100 && !roundIsFinish}
									animated={timeLeftPercentage < 100 && !roundIsFinish}
								/>
							</Flex>
						)}
					</Flex>

					<Flex flex={1} align="center" justify="center">
						<Card
							bg="var(--mantine-color-dark-7)"
							radius="lg"
							style={{ width: "30%", textAlign: "center" }}
							p={10}
						>
							<Title order={2}>{config.game.currentLetter}</Title>
						</Card>
					</Flex>

					<Flex flex={1} justify="end">
						<LeaveButton onClick={() => leaveRoomAction()} />
					</Flex>
				</Flex>
			</Paper>
			{/* Configuration */}
			<Flex style={{ flex: 1, height: "100%", overflow: "auto" }} direction="row" mt={30}>
				{/* Sheet inputs */}
				<Container flex={2} p={0} m={0} mr={30}>
					<Flex direction="column" style={{ height: "100%", overflow: "auto" }} justify="start">
						<Card
							flex={1}
							p={30}
							bg="var(--mantine-color-dark-6)"
							radius="lg"
							style={{ height: "100%", overflow: "auto" }}
						>
							{config.words.map((word, index) => {
								return (
									<Flex key={index} direction="column" mt={index === 0 ? 0 : 40}>
										<Input.Wrapper label={<Title order={3}>{word}</Title>}>
											<Input
												placeholder={`${config.game.currentLetter.toUpperCase()} ...`}
												name="Mot"
												value={answers[word]}
												onChange={e => handleAnswerChange(word, e.target.value)}
												mt={10}
												size="xl"
												radius="lg"
												disabled={roundIsFinish}
												classNames={{
													input: "input-classic-game-word"
												}}
											/>
										</Input.Wrapper>
									</Flex>
								);
							})}
						</Card>
					</Flex>
				</Container>
				{/* Players */}
				<Container
					flex={1}
					p={0}
					m={0}
					style={{
						display: "flex",
						flexDirection: "column",
						height: "100%"
					}}
				>
					<Title order={3}>Joueurs</Title>

					<Container
						p={0}
						m={0}
						style={{
							overflowY: "auto",
							height: "100%"
						}}
					>
						{sortedScoreUser.map((user, index) => {
							const roomUser = room.users.find(roomUser => roomUser.uuid === user.uuid);
							if (!roomUser) return null;
							return (
								<Paper
									key={roomUser.uuid}
									shadow="lg"
									radius="lg"
									p="md"
									bg="var(--mantine-color-dark-6)"
									style={{
										width: "100%"
									}}
									flex="1"
									mt={10}
								>
									<Group
										style={{
											width: "100%"
										}}
										flex="1"
									>
										<UserCard user={roomUser} displayServerStatus={false} displayXpBar={false} />
										<Flex align="center">
											<Text m={0} fw={900} size="xl" mr={5}>
												{user.score}
											</Text>
											<IconAwardFilled m={0} size={20} />
										</Flex>
									</Group>
								</Paper>
							);
						})}
					</Container>
					<StopButtonContainer disabled={roundIsFinish} onClick={handleStopButton} />
				</Container>
			</Flex>
		</Container>
	);
}
