import lod_ from "lodash";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const userSlice = createSlice({
	name: "user",
	initialState,

	reducers: {
		login: (state, action) => {
			let payload = action.payload;
			delete payload._id;
			return {
				loggedIn: true,
				username: payload.username,
				uuid: payload.uuid,
				type: payload.type,
				avatar: payload.avatar,
				...payload
			};
		},
		updateUser(state, action) {
			let payload = action.payload;
			return payload;
		},
		updateUserFields(state, action) {
			let currentState = lod_.cloneDeep(state);
			let fields = action.payload;

			for (let key in fields) {
				lod_.set(currentState, key, fields[key]);
			}
			return currentState;
		},
		logout: state => {
			return {};
		}
	}
});

export const { login, updateUser, updateUserFields, logout } = userSlice.actions;

export const selectUser = state => state.user;

export const isUserLoggedIn = state => state.user.loggedIn;

export default userSlice.reducer;
