/* eslint-disable react-hooks/exhaustive-deps */
import ReactGA from "react-ga4";
import { Button, Container, Divider, Flex, Group, Input, Loader, Text, Title } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { publicRequest } from "helpers/request";
import { DiscordButton } from "pages/Home";
import { GoogleButton } from "pages/Home";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { wsEvent } from "store/actions/wsActions";
import { socket } from "store/middleware/wsMiddleware";
import { getWSConnection } from "store/reducers/serverReducer";
import { selectUser } from "store/reducers/userReducer";

const UnLoggedUser = ({ founder, roomId }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [username, setUsername] = useState();

	const currentBaseURL = window.location.origin;
	const discordClientID = "1227006810322636930";
	const redirectDiscordURL = `${currentBaseURL}/login/`;

	const state = {
		roomId
	};

	const url = `https://discord.com/oauth2/authorize?client_id=${discordClientID}&response_type=token&redirect_uri=${encodeURIComponent(
		redirectDiscordURL
	)}discord&scope=identify&state=${encodeURIComponent(JSON.stringify(state))}`;

	const connectUser = async () => {
		let result = await publicRequest({
			method: "post",
			url: "/login",
			data: {
				user: {
					username: username
				},
				provider: "guest"
			}
		});

		let uuid = result.uuid;

		if (uuid) {
			ReactGA.event({
				category: "Utilisateur",
				action: "Connexion invité",
				label: "Connexion invité"
			});

			// 1- Log in
			socket.emit("login", { uuid: uuid });

			// 2- Join room
			socket.emit("join_room", { userId: uuid, roomId: roomId }, res => {
				// Go to /play => correct page
				navigate("/play");
			});
		} else {
			navigate("/");
		}
	};

	return (
		<Flex
			flex="1"
			direction="column"
			justify="center"
			align="center"
			style={{ height: "100%", width: "100%" }}
		>
			<Container p={30} style={{ width: "60%" }}>
				<Title order={1}>Rejoindre la partie de {founder}</Title>
				<Text size="xl" fw={500}>
					Se connecter avec
				</Text>
				<Group grow mb="md" mt="md">
					<GoogleButton disabled>Google</GoogleButton>
					<DiscordButton component="a" href={url}>
						Discord
					</DiscordButton>
				</Group>
				<Divider my="xs" label="ou" labelPosition="center" />
				<Group grow mb="md" mt="md">
					<Input
						placeholder="Nom d'utilisateur"
						value={username}
						onChange={e => setUsername(e.currentTarget.value)}
						onKeyDown={e => {
							if (e.key === "Enter") {
								connectUser();
							}
						}}
					/>
					<Button disabled={!username?.trim()} variant="filled" onClick={connectUser}>
						Continuer en tant qu'invité
					</Button>
				</Group>
			</Container>
		</Flex>
	);
};

const JoinPage = () => {
	const user = useSelector(selectUser);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();
	const wsConnection = useSelector(getWSConnection);

	const [loading, setLoading] = useState(true);
	const [founder, setFounder] = useState(null);

	useEffect(() => {
		if (!socket || !wsConnection) {
			return;
		}

		setLoading(true);

		// 1- Check if room exists
		socket.emit("get_room_validation", { roomId: id }, res => {
			setLoading(false);
			if (!res.valid) {
				showNotification({
					title: "Erreur",
					message: "La partie n'existe pas",
					color: "red"
				});
				navigate("/");
			} else {
				// If user is logged in just join the room
				if (user.loggedIn) {
					// Join room
					dispatch(
						wsEvent({
							event: "join_room",
							payload: { userId: user.uuid, roomId: id }
						})
					);
					// Go to /play
					navigate("/play");
				} else {
					setFounder(res.founder);
				}
			}
		});
	}, [wsConnection]);

	if (loading) {
		return (
			<Container>
				<Group direction="column" align="center" justify="center" style={{ height: "100vh" }}>
					<Loader />
				</Group>
			</Container>
		);
	}

	return <UnLoggedUser founder={founder} roomId={id} />;
};

export default JoinPage;
