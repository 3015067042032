import lod_ from "lodash";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

// Function to merge arrays
function customizer(objValue, srcValue) {
	// If the value is an array, return the source value
	if (lod_.isArray(objValue)) {
		return srcValue;
	}
}

export const slice = createSlice({
	name: "room",
	initialState,

	reducers: {
		joinRoom(state, action) {
			let payload = action.payload;
			payload.joinedDate = new Date().getTime();
			return payload;
		},
		updateRoom(state, action) {
			let payload = action.payload;
			return payload;
		},
		updateRoomFields(state, action) {
			let currentState = lod_.cloneDeep(state);
			let fields = action.payload;

			for (let key in fields) {
				lod_.set(currentState, key, fields[key]);
			}
			return currentState;
		},
		leaveRoom(state, action) {
			return initialState;
		}
	}
});

export const { joinRoom, updateRoom, updateRoomFields, leaveRoom } = slice.actions;

export default slice.reducer;
