/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Group, Loader } from '@mantine/core';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'store/reducers/userReducer';
import { publicRequest } from 'helpers/request';
import { wsEvent } from 'store/actions/wsActions';
import { socket } from 'store/middleware/wsMiddleware';
/**
 * Login with discord
 * @returns
 */
async function discordLogin(navigate) {
  const fragment = new URLSearchParams(window.location.hash.slice(1));
  const [accessToken, tokenType, state] = [
    fragment.get('access_token'),
    fragment.get('token_type'),
    fragment.get('state'),
  ];

  const parsedState = JSON.parse(decodeURIComponent(state));

  if (!accessToken) {
    return null;
  }

  try {
    let response = await fetch('https://discord.com/api/users/@me', {
      headers: {
        authorization: `${tokenType} ${accessToken}`,
      },
    });

    response = await response.json();

    let user = {
      provider: 'discord',
      username: response.global_name,
      uuid: response.id,
      avatar: `https://cdn.discordapp.com/avatars/${response.id}/${response.avatar}`,
    };

    // 1- Log in
    let result = await publicRequest({
      method: 'post',
      url: '/login',
      data: {
        user: user,
        provider: 'discord',
      },
    });

    let uuid = result.uuid;

    // 2- Join room
    if (parsedState.roomId) {
      socket.emit('join_room', { userId: uuid, roomId: parsedState.roomId }, (res) => {
        // Go to /play => correct page
        navigate('/play');
      });
    }

    return uuid;
  } catch (error) {
    return null;
  }
}
/**
 * Login as guest()
 * @returns
 */
async function guestLogin(state) {
  const { username } = state;

  let result = await publicRequest({
    method: 'post',
    url: '/login',
    data: {
      user: {
        username: username,
      },
      provider: 'guest',
    },
  });

  return result.uuid;
}

/**
 * Main login component
 * @returns
 */
export default function LoginPage() {
  const { provider } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  useEffect(() => {
    if (user.loggedIn) {
      navigate('/');
    }
  }, [user]);

  useEffect(() => {
    async function loginEffect() {
      let uuid = null;
      switch (provider) {
        // Discord login
        case 'discord':
          uuid = await discordLogin(navigate);
          break;
        // Guest login
        case 'guest':
          uuid = await guestLogin(state);
          break;
        case 'join':
          break;
        default:
          break;
      }

      if (uuid) {
        dispatch(
          wsEvent({
            event: 'login',
            payload: { uuid: uuid },
          })
        );
      } else {
        navigate('/');
      }
    }

    loginEffect();
  }, [provider]);

  return (
    <Container fluid>
      <Group direction="column" align="center" justify="center" style={{ height: '90vh' }}>
        <Loader label="Connexion en cours" size="xl" style={{ margin: 'auto' }} />
      </Group>
    </Container>
  );
}
