import { Card, Chip, Flex, Input, rem, Title } from "@mantine/core";
import { IconFile, IconFileFilled, IconSend, IconX } from "@tabler/icons-react";
import { useRef, useState } from "react";
import lod_ from "lodash";
import { socket } from "store/middleware/wsMiddleware";

/**
 * Component Words. Edit or delete words when classic mode is enabled.
 *
 * @param {object} props.room - Room data
 * @returns
 */
const WordsContainer = ({ isAdmin, room }) => {
	const config = room.config;
	const wordInputRef = useRef(null);
	const [newWord, setNewWord] = useState("");

	/**
	 * Add a new word to the list.
	 * @returns {void}
	 */
	const addWord = () => {
		// Do not add if the word is empty or nil
		if (lod_.isNil(newWord) || lod_.isEmpty(newWord)) {
			return;
		}

		// Do not add if word already exists
		if (lod_.includes(config.words, newWord.toUpperCase())) {
			return;
		}

		socket.emit("game_classic_config_words_add_word", {
			roomId: room.roomId,
			word: newWord
		});

		setNewWord("");
		wordInputRef.current?.focus();
	};

	/**
	 * Delete a word from the list.
	 * @param {string} word
	 * @returns {void}
	 */
	const deleteWord = word => {
		socket.emit("game_classic_config_words_delete_word", {
			roomId: room.roomId,
			word: word
		});
	};

	return (
		<Flex
			direction="column"
			fluid
			flex={1}
			style={{ height: "100%", width: "100%", overflowY: "auto" }}
		>
			<Title order={3}>Liste des thèmes</Title>

			{/*
			 * Config mode is random
			 */}
			{config.mode === "random" && (
				<Card
					flex={1}
					mt={10}
					p="xxl"
					bg="var(--mantine-color-dark-6)"
					radius="lg"
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center"
					}}
				>
					<Title order={2} mb={30}>{`Mode Aléatoire`}</Title>
					<Title order={4}>{`Les thèmes restent inconnus jusqu'au début de la partie ! 🤫`}</Title>
				</Card>
			)}

			{/*
			 * Config mode is classic
			 */}
			{config.mode === "classic" && (
				<Card
					flex={1}
					mt={10}
					p="xxl"
					bg="var(--mantine-color-dark-6)"
					radius="md"
					style={{
						display: "flex",
						justifyContent: "space-between",
						overflowY: "auto",
						height: "100%"
					}}
				>
					<Flex
						direction="row"
						align="start"
						justify="start"
						wrap="wrap"
						style={{
							gap: rem(10),
							overflowY: "auto",
							height: "100%",
							alignContent: "flex-start"
						}}
					>
						{(config?.words ?? []).map((word, index) => (
							<Chip
								key={index}
								icon={
									isAdmin() ? (
										<IconX style={{ width: rem(16), height: rem(16) }} />
									) : (
										<IconFileFilled style={{ width: rem(16), height: rem(16) }} />
									)
								}
								color="indigo"
								variant="filled"
								checked
								onClick={() => {
									if (!isAdmin()) {
										return;
									}
									deleteWord(word);
								}}
							>
								{word}
							</Chip>
						))}
					</Flex>
					{/* Input to add a word */}
					{isAdmin() && (
						<Input
							ref={wordInputRef}
							style={{ width: "100%" }}
							placeholder="Ajouter un thème"
							radius="md"
							size="md"
							classNames={{
								input: "input-add-word"
							}}
							value={newWord}
							onChange={e => setNewWord(e.currentTarget.value)}
							onKeyDown={e => {
								if (e.key === "Enter") {
									addWord();
								}
							}}
							rightSectionPointerEvents="all"
							rightSection={
								<IconSend aria-label="Send" onClick={addWord} style={{ cursor: "pointer" }} />
							}
						></Input>
					)}
				</Card>
			)}
		</Flex>
	);
};

export default WordsContainer;
