/* eslint-disable react-hooks/exhaustive-deps */
import "./flash.style.css";
import { Flex, Loader } from "@mantine/core";
import { generateRandomUUID } from "helpers/utils";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { socket } from "store/middleware/wsMiddleware";
import { selectUser } from "store/reducers/userReducer";
import { isUserLoggedIn } from "store/reducers/userReducer";
import FlashWaitingComponent from "./flash.waiting";
import FlashGameComponent from "./flash.game";
import { getWSConnection } from "store/reducers/serverReducer";
import FlashEndComponent from "./flash.end";

const FlashGamePage = () => {
	const userLoggedIn = useSelector(isUserLoggedIn);
	const user = useSelector(selectUser);

	const wsConnection = useSelector(getWSConnection);

	const [loading, setLoading] = useState(true);
	const [userGame, setUserGame] = useState(null);
	const [uuid, setUUID] = useState(null);

	useEffect(() => {
		if (!socket) {
			return;
		}

		let tmpUUID = null;
		if (userLoggedIn) {
			tmpUUID = user.uuid;
		} else {
			tmpUUID = generateRandomUUID();
		}

		let localStorageFlashUUID = localStorage.getItem("flashUUID");
		if (!localStorageFlashUUID) {
			localStorage.setItem("flashUUID", tmpUUID);
		} else {
			tmpUUID = localStorageFlashUUID;
		}

		setUUID(tmpUUID);
		socket.emit("getTodayUserFlashGame", { uuid: tmpUUID }, data => {
			setUserGame(data);
			setLoading(false);
		});

		socket.on("updateUserFlashGame", data => {
			// console.log("updateUserFlashGame", data);
			setUserGame(data);
		});

		return () => {
			socket.off("updateUserFlashGame");
		};
	}, [wsConnection]);

	if (loading) {
		return (
			<Flex flex={1} align="center" justify="center" style={{ height: "calc(100vh - 100px)" }}>
				<Loader color="blue" />
			</Flex>
		);
	}

	if (userGame) {
		switch (userGame.status) {
			case "inProgress":
				return <FlashGameComponent uuid={uuid} game={userGame} />;
			case "finished":
				return <FlashEndComponent uuid={uuid} game={userGame} />;
			default:
				return <div>Bienvenue dans les backrooms</div>;
		}
	} else {
		return <FlashWaitingComponent uuid={uuid} />;
	}
};

export default FlashGamePage;
