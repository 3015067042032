import "./style.css";
import ReactGA from "react-ga4";
import {
	ActionIcon,
	Button,
	Card,
	Container,
	Divider,
	Flex,
	Group,
	Paper,
	Tabs,
	Text,
	Title,
	Tooltip,
	rem
} from "@mantine/core";
import {
	IconCircleCheckFilled,
	IconDoorExit,
	IconLock,
	IconLockOpen,
	IconThumbDown,
	IconThumbUp
} from "@tabler/icons-react";
import { ButtonCopy } from "components/ButtonCopy";
import { LeaveButton } from "components/LeaveButton";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Typical from "react-typical";
import { wsEvent } from "store/actions/wsActions";
import { selectUser } from "store/reducers/userReducer";
import { socket } from "store/middleware/wsMiddleware";
import { updateRoomFields } from "store/reducers/roomReducer";
import WordsContainer from "./components/WordsContainer";
import StartGameContainer from "./components/StartGameContainer";
import UserCard from "components/UserCard";

class PlayersTitle extends React.PureComponent {
	render() {
		const playersTitles = [
			"Les scribouilleurs ✏️",
			"Les gratteurs 📝",
			"Les trouvailleurs 🔍",
			"Les écrivailleurs 📚",
			"Les cervelo 🧠",
			"Les flashmeurs ⚡",
			"Les funscribes 🎉",
			"Les brainstormeurs 💭",
			"Les inspirés 💡",
			"Les intellectuels 🤓"
		];

		return (
			<Typical
				steps={playersTitles.flatMap(participant => [participant, 3000])}
				loop={Infinity}
				// wrapper="span"
			/>
		);
	}
}

export default function PrivateRoom() {
	const room = useSelector(state => state.room);
	const config = room?.config ?? {};

	const dispatch = useDispatch();
	const user = useSelector(selectUser);

	const currentUserInRoom = room.users.find(u => u.uuid === user.uuid);

	const isAdmin = (potentialUser = null) => {
		if (!potentialUser) {
			return currentUserInRoom.admin ?? false;
		}
		return room.users.find(u => u.uuid === potentialUser.uuid)?.admin ?? false;
	};

	const isFounder = () => {
		let roomUser = room.users.find(u => u.uuid === user.uuid);
		return roomUser?.founder ?? false;
	};

	const leaveRoomAction = (uuid = user.uuid) => {
		dispatch(
			wsEvent({
				event: "user_leave_room",
				payload: {
					roomId: room.roomId,
					userId: uuid
				}
			})
		);
	};

	/**
	 * Change room visibility
	 * @returns {void}
	 */
	const changeRoomVisibility = () => {
		if (!isAdmin()) {
			return;
		}

		ReactGA.event({
			category: "Interaction",
			action: "Changement de visibilité partie classique",
			label: "Changement de visibilité partie classique"
		});

		dispatch(
			wsEvent({
				event: "update_room",
				payload: {
					roomId: room.roomId,
					data: {
						visibility: room.visibility === "private" ? "public" : "private"
					}
				}
			})
		);
	};

	/**
	 * Change user ready state
	 */
	const changeUserReadyState = () => {
		dispatch(
			wsEvent({
				event: "update_user_room",
				payload: {
					roomId: room.roomId,
					userId: user.uuid,
					data: {
						ready: !currentUserInRoom.ready
					}
				}
			})
		);
	};

	/**
	 * Update party config
	 * @param {*} key
	 * @param {*} value
	 */
	const updatePartyConfig = (key, value, extra = {}) => {
		// 1- Update in the store to get a real-time update
		dispatch(
			updateRoomFields({
				[`config.${key}`]: value,
				...extra
			})
		);
		// 2- Emit to the server
		socket.emit("update_room", {
			roomId: room.roomId,
			data: {
				[`config.${key}`]: value,
				...extra
			}
		});
	};

	return (
		<Container
			m={0}
			p={0}
			fluid
			flex="1"
			style={{
				position: "relative",
				height: "calc(100vh - 2rem)",
				display: "flex",
				flexDirection: "column"
			}}
		>
			<Paper shadow="lg" radius="xl" p="md" bg="var(--mantine-color-dark-6)">
				<Flex direction="row" align="center" justify="space-between">
					<Flex direction="row" align="center">
						<Button
							variant="light"
							rightSection={
								room.visibility === "private" ? (
									<IconLock style={{ width: rem(20), height: rem(20) }} />
								) : (
									<IconLockOpen style={{ width: rem(20), height: rem(20) }} />
								)
							}
							radius="xl"
							size="md"
							styles={{
								root: { paddingRight: rem(14), height: rem(48) },
								section: { marginLeft: rem(22) }
							}}
							color={room.visibility === "private" ? "red" : "green"}
							onClick={() => changeRoomVisibility()}
						>
							{room.visibility === "private" ? "Partie privée" : "Partie ouverte"}
						</Button>
						<Text size="lg" style={{ marginLeft: rem(20), fontWeight: 500 }}>
							{room.name}
						</Text>
					</Flex>

					<Group>
						<ButtonCopy url={`${window.location.origin}/join/${room.roomId}`} />

						<Button
							variant="light"
							rightSection={
								currentUserInRoom.ready ? (
									<IconThumbUp style={{ width: rem(20), height: rem(20) }} />
								) : (
									<IconThumbDown style={{ width: rem(20), height: rem(20) }} />
								)
							}
							radius="xl"
							size="md"
							styles={{
								root: { paddingRight: rem(14), height: rem(48) },
								section: { marginLeft: rem(22) }
							}}
							color={currentUserInRoom.ready ? "green" : "red"}
							onClick={() => changeUserReadyState()}
						>
							{currentUserInRoom.ready ? "Pas prêt" : "Prêt"}
						</Button>

						<LeaveButton onClick={() => leaveRoomAction()} />
					</Group>
				</Flex>
			</Paper>
			{/* Configuration */}
			<Flex style={{ flex: 1, height: "100%", overflow: "auto" }} direction="row" mt={30}>
				{/* Settings */}
				<Container flex={1.7} p={0} m={0} mr={30}>
					<Flex direction="column" style={{ height: "100%", overflow: "auto" }} justify="start">
						{/* Settings container */}
						<Container fluid flex={1} p={0} m={0} style={{ height: "100%", width: "100%" }}>
							<Title order={3}>Paramètres de la partie</Title>

							<Card flex={1} mt={10} p="xxl" bg="var(--mantine-color-dark-6)" radius="lg">
								{/* Party config mode */}
								<Flex mt={10} justify="space-between" align="center">
									<Text size="md" fw={600} order={4} color="var(--mantine-color-dark-1)">
										Mode de jeu
									</Text>
									<Tabs
										value={config.mode}
										onChange={value => {
											if (!isAdmin()) {
												return;
											}
											let extra = {};
											extra["config.words"] = [];
											updatePartyConfig("mode", value, extra);
										}}
										variant="pills"
									>
										<Tabs.List>
											<Tabs.Tab fw={900} value="random">
												Aléatoire
											</Tabs.Tab>
											<Tabs.Tab fw={900} value="classic">
												Classique
											</Tabs.Tab>
										</Tabs.List>
									</Tabs>
								</Flex>
								<Divider mt={10} />

								{/* Party config timer */}
								<Flex mt={10} justify="space-between" align="center">
									<Text size="md" fw={600} order={4} color="var(--mantine-color-dark-1)">
										Durée d'une manche
									</Text>
									<Tabs
										value={config.timer?.toString() ?? "none"}
										onChange={value => {
											if (!isAdmin()) {
												return;
											}
											updatePartyConfig("timer", value);
										}}
										variant="pills"
									>
										<Tabs.List>
											<Tabs.Tab fw={900} value="none">
												Illimité
											</Tabs.Tab>
											<Tabs.Tab fw={900} value="30">
												30s
											</Tabs.Tab>
											<Tabs.Tab fw={900} value="60">
												60s
											</Tabs.Tab>
											<Tabs.Tab fw={900} value="90">
												90s
											</Tabs.Tab>
										</Tabs.List>
									</Tabs>
								</Flex>
								<Divider mt={10} />

								{/* Party config rounds */}
								<Flex mt={10} justify="space-between" align="center">
									<Text size="md" fw={600} order={4} color="var(--mantine-color-dark-1)">
										Nombre de manche
									</Text>
									<Tabs
										value={config.rounds?.toString() ?? "none"}
										onChange={value => {
											if (!isAdmin()) {
												return;
											}
											updatePartyConfig("rounds", value);
										}}
										variant="pills"
									>
										<Tabs.List>
											<Tabs.Tab fw={900} value="3">
												3
											</Tabs.Tab>
											<Tabs.Tab fw={900} value="5">
												5
											</Tabs.Tab>
											<Tabs.Tab fw={900} value="10">
												10
											</Tabs.Tab>
											<Tabs.Tab fw={900} value="20">
												20
											</Tabs.Tab>
										</Tabs.List>
									</Tabs>
								</Flex>
								<Divider mt={10} />
							</Card>
						</Container>
						{/* Words */}
						<WordsContainer isAdmin={isAdmin} room={room} />
					</Flex>
				</Container>
				{/* Players */}
				<Container
					flex={1}
					p={0}
					m={0}
					style={{
						display: "flex",
						flexDirection: "column",
						height: "100%"
					}}
				>
					<Title order={3}>
						<PlayersTitle />
					</Title>

					<Container
						p={0}
						m={0}
						style={{
							overflowY: "auto",
							height: "100%"
						}}
					>
						{room.users.map(roomUser => (
							<Paper
								key={roomUser.uuid}
								shadow="lg"
								radius="lg"
								p="md"
								bg="var(--mantine-color-dark-6)"
								style={{
									width: "100%"
								}}
								flex="1"
								mt={10}
							>
								<Group
									style={{
										width: "100%"
									}}
									flex="1"
								>
									<UserCard user={roomUser} displayServerStatus={false} displayXpBar={false} />

									<Flex direction="row" align="center">
										<IconCircleCheckFilled
											style={{
												width: rem(30),
												height: rem(30),
												color: roomUser.ready
													? "var(--mantine-color-green-6)"
													: "var(--mantine-color-dark-1)"
											}}
											stroke={1.5}
										/>
										{isFounder(roomUser) && (
											<Tooltip
												label="Exclure de la partie"
												position="left"
												withArrow
												transition="fade"
												bg="var(--mantine-color-dark-1)"
											>
												<Flex direction="row" align="center" ml={10}>
													<ActionIcon
														variant="transparent"
														aria-label="Settings"
														disabled={
															room.users.find(u => u.uuid === user.uuid).uuid === roomUser.uuid
														}
														onClick={() => {
															leaveRoomAction(roomUser.uuid);
														}}
													>
														<IconDoorExit
															style={{
																width: rem(20),
																height: rem(20)
															}}
														/>
													</ActionIcon>
												</Flex>
											</Tooltip>
										)}
									</Flex>
								</Group>
							</Paper>
						))}
					</Container>

					<StartGameContainer room={room} isFounder={isFounder} />
				</Container>
			</Flex>
		</Container>
	);
}
