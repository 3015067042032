import "./style.css";
import { Grid, Text, Card, Flex, Badge, Container, Avatar, Modal, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconUser } from "@tabler/icons-react";
import { useSelector } from "react-redux";

export function BadgeGallery({ user }) {
	const badges = useSelector(state => state.datas.badges);

	const getBadgeType = type => {
		switch (type) {
			case "legendary":
				return (
					<Text ml={5} fz="xs" fw={900} color="gold">
						LEGENDAIRE
					</Text>
				);
			default:
				return null;
		}
	};

	const userBadges = user.badges ?? [];

	return (
		<Container p={0} m={0}>
			<Grid>
				{badges.map((badge, index) => {
					let hasBadge = userBadges.includes(badge.code);
					let display = true;
					if (badge.hidden) {
						if (userBadges.includes(badge.code)) {
							display = true;
						} else {
							display = false;
						}
					}
					if (!display) return null;

					if (badge.code === "checked" && user.type === "client") {
						hasBadge = true;
					}

					return (
						<Grid.Col
							key={index}
							span={4}
							style={{
								filter: hasBadge ? "none" : "grayscale(1) opacity(0.5)"
							}}
						>
							<Card
								withBorder
								radius="md"
								padding="lg"
								bg="var(--mantine-color-dark-6)"
								style={{ height: "100%" }}
							>
								<Flex align="center" justify="space-between">
									<Flex align="center">
										{/* <Badge
											mr={5}
											style={{
												backgroundColor: badge.color
											}}
										>
											{badge.emoji}
										</Badge> */}
										<Avatar
											mr={10}
											key={index}
											variant="filled"
											radius="xl"
											size="sm"
											color={badge.color}
										>
											{badge.emoji}
										</Avatar>
										<Text fz="sm" fw={800}>
											{badge.name}
										</Text>
									</Flex>

									{badge.type && <>{getBadgeType(badge.type)}</>}
								</Flex>
								<Text mt={15} fz="sm">
									{badge.description}
								</Text>
							</Card>
						</Grid.Col>
					);
				})}
			</Grid>
		</Container>
	);
}

const BadgeModal = ({ user, open, badges, handleClose }) => {
	const userBadgeCount = badges.filter(badge => (user.badges ?? []).includes(badge.code)).length;

	return (
		<Modal
			opened={open}
			onClose={handleClose}
			transitionProps={{ transition: "scale-y" }}
			title={
				<Flex align="center">
					<Avatar radius="xl" color="indigo" src={user.avatar} mr={10}>
						{!user.avatar && <IconUser />}
					</Avatar>

					<Text size="lg" fw={800}>
						{user.username}
					</Text>

					<Text ml={10}>{`(${userBadgeCount} / ${badges.length})`}</Text>
				</Flex>
			}
			size="xl"
			centered
		>
			<Text
				className="badge-text-label"
				style={{
					textAlign: "center"
				}}
				mb={50}
			>
				Les badges ne sont pas encore disponibles, ils le seront prochainement
			</Text>
			<BadgeGallery user={user} />
		</Modal>
	);
};

export function BadgeList({ user, maxBadges = 3, displayBadgesCount = true }) {
	const [opened, { open, close }] = useDisclosure(false);
	const badges = useSelector(state => state.datas.badges);
	const userBadges = user.badges ?? [];

	if (!userBadges) return null;

	const firstUserBadges = userBadges.slice(0, maxBadges);

	return (
		<>
			{/*  */}
			<Tooltip label="Voir les badges" position="top">
				<Avatar.Group
					spacing="8"
					onClick={e => {
						e.stopPropagation();
						e.preventDefault();
						open();
					}}
				>
					{firstUserBadges.map((badge, index) => {
						const badgeData = badges.find(b => b.code === badge);
						return (
							<Avatar key={index} variant="filled" radius="xl" size="sm" color={badgeData.color}>
								{badgeData.emoji}
							</Avatar>
						);
					})}
					{userBadges.length > maxBadges && displayBadgesCount && (
						<Avatar size="sm">+{userBadges.length - maxBadges}</Avatar>
					)}
				</Avatar.Group>
			</Tooltip>
			{/*  */}
			<BadgeModal user={user} badges={badges} open={opened} handleClose={close} />
		</>
	);
}
