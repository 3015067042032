import { Avatar, Group } from "@mantine/core";
import { IconUser } from "@tabler/icons-react";
import UserLevel from "components/UserButton/components/Level";
import { useSelector } from "react-redux";
import { getWSConnection } from "store/reducers/serverReducer";

const UserCard = ({ user, displayServerStatus = true, displayXpBar = true }) => {
	const wsConnection = useSelector(getWSConnection);

	return (
		<Group style={{ flex: 1 }}>
			<div style={{ position: "relative" }}>
				<Avatar radius="xl" color="indigo" src={user.avatar}>
					{!user.avatar && <IconUser />}
				</Avatar>

				{displayServerStatus && (
					<div
						style={{
							position: "absolute",
							top: "0px",
							right: "0px",
							width: "10px",
							height: "10px",
							borderRadius: "50%",
							backgroundColor: wsConnection ? "green" : "red",
							zIndex: 1
						}}
					/>
				)}
			</div>

			<div style={{ flex: 1 }}>
				<UserLevel user={user} displayXpBar={displayXpBar} />
			</div>
		</Group>
	);
};

export default UserCard;
