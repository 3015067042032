import "./flash.style.css";
import { Button, Card, Container, Flex, Grid, Group, Text, Title, rem } from "@mantine/core";
import { IconFlame, IconFolder } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { socket } from "store/middleware/wsMiddleware";
import { getWSConnection } from "store/reducers/serverReducer";

const FlashWaitingComponent = ({ uuid }) => {
	const [themesList, setThemesList] = useState([]);
	const wsConnection = useSelector(getWSConnection);

	const [dailyScore, setDailyScore] = useState(null);

	const createNewGame = () => {
		socket.emit("createFlashGame", { uuid }, data => {
			// console.log("createFlashGame", data);
		});
	};

	useEffect(() => {
		if (!socket) return;

		socket.emit("flash_page_get_daily_score", {}, score => {
			setDailyScore(score);
		});
	}, [wsConnection]);

	useEffect(() => {
		if (!socket) return;

		socket.emit("getThemesList", {}, data => {
			setThemesList(data);
		});
	}, []);

	return (
		<Container m={0} p={0} fluid>
			<Group direction="column" align="center" justify="space-between" mb={20}>
				<Title order={1}>🔥 Partie flash</Title>
			</Group>

			<Flex>
				<Card flex="1" radius="md" bg="var(--mantine-color-dark-5)">
					<Flex align="center">
						<IconFlame
							style={{
								width: rem(32),
								height: rem(32),
								color: `var(--mantine-color-red-6)`
							}}
							stroke={1.5}
						/>
						<Title order={3} ml="xs">
							Règles du jeu
						</Title>
					</Flex>

					<Flex mt="md" direction="column">
						<Text>
							Chaque jour, une grille de 10 <b>thèmes</b> différents vous attend
						</Text>
						<Text mt="xs">
							Votre mission ? Soyez le plus <b>rapide</b> pour résoudre les thèmes et remportez un
							maximum de points ! 🏆
						</Text>
						<Text mt="xs">Mais attention, chaque erreur vous coûtera des points ! 😱</Text>
						<Text mt="xs">
							Utilisez des lettres <b>rares</b> et des <b>mots longs</b> pour maximiser vos gains !
							🚀
						</Text>
						<Text mt="xs">
							Si vous pensez qu'un mot correct a été mal jugé, vous pouvez le signaler en cliquant
							sur le bouton <span>Signaler</span> situé en bas à droite du mot dans l'historique.
						</Text>
					</Flex>
				</Card>
				<Flex flex="1" direction="column" ml="md">
					<Card
						flex="1"
						radius="md"
						bg="var(--mantine-color-dark-5)"
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center"
						}}
					>
						<Title order={3}>{`${new Date().toLocaleDateString("fr-FR", {
							weekday: "long",
							year: "numeric",
							month: "long",
							day: "numeric"
						})}`}</Title>
						{!dailyScore && <Text mt="md">Aucun record n'a été établi aujourd'hui</Text>}
						{dailyScore && (
							<Text mt="md">
								Record du jour à battre : <b>{`${dailyScore} points`}</b>
							</Text>
						)}
					</Card>
					<Button
						// variant="light"
						//	variant="gradient"
						gradient={{ from: "blue", to: "cyan", deg: 90 }}
						rightSection={<IconFlame style={{ width: rem(20), height: rem(20) }} stroke={1.5} />}
						radius="md"
						size="xl"
						color="indigo"
						onClick={createNewGame}
						mt="md"
					>
						Faire les thèmes flash du jour
					</Button>
				</Flex>
			</Flex>
			{/* Themes */}
			<Card
				flex="1"
				radius="md"
				withBorder
				bg="var(--mantine-color-body)"
				style={{
					marginTop: rem(60)
				}}
			>
				<Flex align="center">
					<IconFolder
						style={{
							width: rem(32),
							height: rem(32),
							color: `var(--mantine-color-indigo-6)`
						}}
						stroke={1.5}
					/>
					<Title order={3} ml="xs">
						Tous les thèmes
					</Title>
				</Flex>

				<Grid mt="md">
					{themesList.map((theme, index) => (
						<Grid.Col key={index} span={2}>
							<Card
								flex="1"
								radius="md"
								bg="linear-gradient(145deg, var(--mantine-color-dark-4) 0%, var(--mantine-color-dark-6) 100%)"
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center"
								}}
							>
								<Text>{theme.emoji}</Text>
								<Text>{theme.label}</Text>
							</Card>
						</Grid.Col>
					))}
				</Grid>
			</Card>
		</Container>
	);
};

export default FlashWaitingComponent;
