import { Button, Container, rem } from "@mantine/core";

/**
 * Component to display the start game container button
 * @param {object} props.room - Room data
 * @returns
 */
const ReadyButtonContainer = ({ disabled = false, onClick }) => {
	return (
		<Container
			mt={20}
			p="sm"
			bg="var(--mantine-color-dark-6)"
			shadow="xl"
			radius="xl"
			style={{ width: "100%", borderRadius: rem(20) }}
		>
			{/* <Flex direction="column" align="center" justify="center"> */}
			{/* <Text size="xs" align="center" style={{ fontWeight: 500 }}>
					Tous les joueurs doivent être prêts pour lancer la partie
				</Text> */}
			<Button
				variant="light"
				radius="lg"
				size="sm"
				disabled={disabled}
				styles={{
					root: {
						width: "100%",
						height: rem(48)
					}
				}}
				color="indigo"
				onClick={onClick}
			>
				Prêt
			</Button>
			{/* </Flex> */}
		</Container>
	);
};

export default ReadyButtonContainer;
