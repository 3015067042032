import axios from "axios";

export async function publicRequest({ method, url, data }) {
  try {
    let axiosResult = await axios({
      method: method,
      url: process.env.REACT_APP_BACKEND_URL + "/public/v1" + url,
      data: data,
    });

    return axiosResult.data;
  } catch (error) {
    return {
      error: error,
    };
  }
}

export async function apiRequest({ method, url, data }) {
  try {
    let axiosResult = await axios({
      method: method,
      url: process.env.REACT_APP_BACKEND_URL + "/api/v1" + url,
      data: data,
    });

    return axiosResult.data;
  } catch (error) {
    return {
      error: error,
    };
  }
}
