/* eslint-disable react-hooks/exhaustive-deps */
import "./style.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { wsEvent } from "store/actions/wsActions";
import { selectUser } from "store/reducers/userReducer";
import { socket } from "store/middleware/wsMiddleware";
import { Card, Container, Flex, Group, Paper, Table, Text, Title, rem } from "@mantine/core";
import { IconAwardFilled, IconCircleCheckFilled } from "@tabler/icons-react";
import { LeaveButton } from "components/LeaveButton";
import VoteLine from "./components/ReviewScreen/VoteLine";
import ReadyButtonContainer from "./components/ReviewScreen/ReadyButtonContainer";
import UserCard from "components/UserCard";

/**
 * Classic game review round screen
 */
export default function ClassicScreenReviewRound() {
	const room = useSelector(state => state.room);
	const config = room?.config ?? {};

	const dispatch = useDispatch();
	const user = useSelector(selectUser);

	const [sortedScoreUser, setSortedScoreUser] = useState([]);

	const leaveRoomAction = (uuid = user.uuid) => {
		dispatch(
			wsEvent({
				event: "user_leave_room",
				payload: {
					roomId: room.roomId,
					userId: uuid
				}
			})
		);
	};

	const userSetReadyForNextRound = () => {
		socket.emit("game_classic_user_review_round_ready", { roomId: room.roomId, userId: user.uuid });
	};

	useEffect(() => {
		const filteredScoreUser = [];
		const score = config.game.score;
		// Sort the score object by score
		for (const [key, value] of Object.entries(score)) {
			filteredScoreUser.push({ uuid: key, score: value });
		}
		filteredScoreUser.sort((a, b) => b.score - a.score);
		// Add every user to the filteredScoreUser array that are not in the score object
		room.users.forEach(user => {
			if (!filteredScoreUser.find(scoreUser => scoreUser.uuid === user.uuid)) {
				filteredScoreUser.push({ uuid: user.uuid, score: 0 });
			}
		});
		setSortedScoreUser(filteredScoreUser);
	}, []);

	return (
		<Container
			m={0}
			p={0}
			fluid
			flex="1"
			style={{
				position: "relative",
				height: "calc(100vh - 2rem)",
				display: "flex",
				flexDirection: "column"
			}}
		>
			<Paper shadow="lg" radius="xl" p="md" bg="var(--mantine-color-dark-6)">
				<Flex direction="row" align="center" justify="space-between">
					<Flex flex={1} align="center">
						<Title
							order={3}
							style={{ marginLeft: rem(20), whiteSpace: "nowrap" }}
						>{`${config.game.currentRound} / ${config.rounds}`}</Title>
					</Flex>

					<Flex flex={1} align="center" justify="center">
						<Card
							bg="var(--mantine-color-dark-7)"
							radius="lg"
							style={{ width: "30%", textAlign: "center" }}
							p={10}
						>
							<Title order={2}>{config.game.currentLetter}</Title>
						</Card>
					</Flex>

					<Flex flex={1} justify="end">
						<LeaveButton onClick={() => leaveRoomAction()} />
					</Flex>
				</Flex>
			</Paper>
			{/* Configuration */}
			<Flex style={{ flex: 1, height: "100%", overflow: "auto" }} direction="row" mt={30}>
				{/* Sheet inputs */}
				<Container flex={2} p={0} m={0} mr={30}>
					<Flex direction="column" style={{ height: "100%", overflow: "auto" }} justify="start">
						<Card
							flex={1}
							p={30}
							bg="var(--mantine-color-dark-6)"
							radius="lg"
							style={{ height: "100%", overflow: "auto" }}
						>
							{config.words.map((theme, index) => {
								return (
									<Flex key={index} direction="column" mt={index === 0 ? 0 : 40}>
										<Title order={3}>{theme}</Title>
										<Table mt={10}>
											<Table.Thead>
												<Table.Tr>
													<Table.Th style={{ width: "25%" }}>JOUEUR</Table.Th>
													<Table.Th style={{ width: "25%" }}>MOT</Table.Th>
													<Table.Th style={{ width: "25%", textAlign: "center" }}>AVIS</Table.Th>
													<Table.Th style={{ width: "25%", textAlign: "right" }}>VOTE</Table.Th>
												</Table.Tr>
											</Table.Thead>
											<Table.Tbody>
												{room.users.map((rowUser, index) => {
													return (
														<VoteLine
															key={index}
															room={room}
															theme={theme}
															user={user}
															rowUser={rowUser}
														/>
													);
												})}
											</Table.Tbody>
										</Table>
									</Flex>
								);
							})}
						</Card>
					</Flex>
				</Container>
				{/* Players */}
				<Container
					flex={1}
					p={0}
					m={0}
					style={{
						display: "flex",
						flexDirection: "column",
						height: "100%"
					}}
				>
					<Title order={3}>Joueurs</Title>

					<Container
						p={0}
						m={0}
						style={{
							overflowY: "auto",
							height: "100%"
						}}
					>
						{sortedScoreUser.map((user, index) => {
							const roomUser = room.users.find(roomUser => roomUser.uuid === user.uuid);
							if (!roomUser) return null;
							return (
								<Paper
									key={roomUser.uuid}
									shadow="lg"
									radius="lg"
									p="md"
									bg="var(--mantine-color-dark-6)"
									style={{
										width: "100%"
									}}
									flex="1"
									mt={10}
								>
									<Group
										style={{
											width: "100%"
										}}
										flex="1"
									>
										<UserCard user={roomUser} displayServerStatus={false} displayXpBar={false} />

										<Flex direction="row" align="center">
											<IconCircleCheckFilled
												style={{
													width: rem(30),
													height: rem(30),
													color: roomUser.ready
														? "var(--mantine-color-green-6)"
														: "var(--mantine-color-dark-1)"
												}}
												stroke={1.5}
											/>
										</Flex>

										<Flex align="center">
											<Text m={0} fw={900} size="xl" mr={5}>
												{user.score}
											</Text>
											<IconAwardFilled m={0} size={20} />
										</Flex>
									</Group>
								</Paper>
							);
						})}
					</Container>
					<ReadyButtonContainer onClick={userSetReadyForNextRound} />
				</Container>
			</Flex>
		</Container>
	);
}
