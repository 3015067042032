import { Flex, Progress, Text, Tooltip } from "@mantine/core";

const UserLevel = ({ user, displayXpBar = true }) => {
	const { level, xpInCurrentLevel, xpToNextLevel } = calculateLevel(user.xp);
	const xpInCurrentLevelPercent = (xpInCurrentLevel / xpToNextLevel) * 100;

	function calculateLevel(xpTotal, baseXP = 100, multiplier = 1.1) {
		let level = 1;
		let xpForNextLevel = baseXP;

		// Loop until the total XP is less than the XP required for the next level
		while (xpTotal >= xpForNextLevel) {
			xpTotal -= xpForNextLevel;
			level++;
			xpForNextLevel = Math.floor(baseXP * Math.pow(multiplier, level - 1));
		}

		return {
			level,
			xpInCurrentLevel: xpTotal,
			xpToNextLevel: xpForNextLevel
		};
	}

	return (
		<Flex
			direction="column"
			align="end"
			style={{
				width: "100%"
			}}
		>
			<Flex
				direction="row"
				justify="space-between"
				align="end"
				style={{
					width: "100%"
				}}
			>
				<Text size="md" fw={700}>
					{user.username}
				</Text>
				{user.type !== "guest" && (
					<Text size="sm" fw={500}>
						Nv. <span style={{ fontWeight: "900" }}>{level}</span>
					</Text>
				)}
			</Flex>

			{displayXpBar && user.type !== "guest" && (
				<Tooltip position="top" arrow label={`${xpInCurrentLevel} / ${xpToNextLevel}`}>
					<Progress
						style={{
							width: "100%"
						}}
						color="indigo"
						value={xpInCurrentLevelPercent}
					/>
				</Tooltip>
			)}
		</Flex>
	);
};

export default UserLevel;
