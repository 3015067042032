import { Badge } from "@mantine/core";
import {
  IconHome,
  IconDeviceGamepad2,
  IconSortAscending,
  IconBolt,
  IconBook,
} from "@tabler/icons-react";
import Home from "pages/Home";
import JoinPage from "pages/Join";
import LoginPage from "pages/Login";
import Waiting from "pages/Waiting";
import FlashGamePage from "pages/games/flash";

export const routes = [
  {
    path: "/",
    component: <Home />,
    label: "Accueil",
    icon: <IconHome size="1rem" stroke={1.5} />,
    private: false,
  },
  {
    path: "/play",
    component: <Waiting />,
    label: "Petit bac",
    icon: <IconBook size="1rem" stroke={1.5} />,
    private: true,
    options: {
      displayLoginSnackbar: true,
    },
    badge: (
      <Badge size="xs" color="indigo">
        Classique ✏️
      </Badge>
    ),
  },
  {
    path: "/play/flash",
    component: <FlashGamePage />,
    label: "Partie Flash",
    icon: <IconBolt size="1rem" stroke={1.5} />,
    private: false,
    options: {
      displayLoginSnackbar: true,
    },
    badge: (
      <Badge size="xs" color={`var(--mantine-color-red-6)`}>
        Tendance 🔥
      </Badge>
    ),
  },
  // {
  //   path: "/leaderboard",
  //   component: <div>leaderboard</div>,
  //   label: "Leaderboard",
  //   icon: <IconSortAscending size="1rem" stroke={1.5} />,
  //   private: false,
  // },
  {
    path: "/join/:id",
    component: <JoinPage />,
    label: "Rejoindre",
    private: false,
    hidden: true,
  },
  {
    path: "/login/:provider",
    component: <LoginPage />,
    label: "Rejoindre",
    private: false,
    hidden: true,
  },
];
