import lod_ from "lodash";
import { ActionIcon, Flex, Progress, Table, Tooltip } from "@mantine/core";
import { IconThumbDownFilled, IconThumbUpFilled } from "@tabler/icons-react";
import { socket } from "store/middleware/wsMiddleware";

/**
 * Vote line for the review screen
 * @param {*} param0
 * @returns
 */
export default function VoteLine({ room, theme, user, rowUser }) {
	const config = room?.config ?? {};
	const userWords = config.game.words[rowUser.uuid];

	if (lod_.isNil(userWords) || lod_.isEmpty(userWords)) {
		return null;
	}

	// Word object for the current row user
	const themeWord = userWords[theme];

	// Get the votes for the current word
	const votes = themeWord.votes;
	const votesValues = Object.values(votes);

	const activeUsersCount = room.users.length;

	// Compute the votes count and percentage
	const voteYesCount = votesValues.filter(vote => vote === true).length;
	const voteNoCount = votesValues.filter(vote => vote === false).length;

	const voteYesPercentage = (voteYesCount / activeUsersCount) * 100;
	const voteNoPercentage = (voteNoCount / activeUsersCount) * 100;

	const loggedUserVote = votes[user.uuid];

	const handleChangeVote = value => {
		if (value === loggedUserVote) {
			return;
		}

		// Send the vote to the server
		socket.emit("game_classic_change_vote", {
			roomId: room.roomId,
			userRowId: rowUser.uuid,
			theme: theme,
			loggedUserId: user.uuid,
			vote: value
		});
	};

	return (
		<Table.Tr>
			<Table.Td>{rowUser.username}</Table.Td>
			<Table.Td>{themeWord.word}</Table.Td>
			<Table.Td>
				<Progress.Root size="md">
					<Tooltip label={`Oui (${voteYesCount})`}>
						<Progress.Section value={voteYesPercentage} color="indigo"></Progress.Section>
					</Tooltip>
					<Tooltip label={`Non (${voteNoCount})`}>
						<Progress.Section value={voteNoPercentage} color="grape"></Progress.Section>
					</Tooltip>
				</Progress.Root>
			</Table.Td>
			<Table.Td>
				<Flex justify="end">
					<ActionIcon
						variant={loggedUserVote === true ? "filled" : "transparent"}
						color="indigo"
						aria-label="Settings"
						onClick={() => handleChangeVote(true)}
					>
						<IconThumbUpFilled style={{ width: "70%", height: "70%" }} stroke={1.5} />
					</ActionIcon>
					<ActionIcon
						ml={10}
						variant={loggedUserVote === false ? "filled" : "transparent"}
						color="grape"
						aria-label="Settings"
						onClick={() => handleChangeVote(false)}
					>
						<IconThumbDownFilled style={{ width: "70%", height: "70%" }} stroke={1.5} />
					</ActionIcon>
				</Flex>
			</Table.Td>
		</Table.Tr>
	);
}
