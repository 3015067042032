import user from './userReducer';
import server from './serverReducer';
import room from './roomReducer';
import datas from './datasReducer';

const rootReducer = {
  user,
  room,
  server,
  datas,
};

export default rootReducer;
