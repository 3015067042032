import { Button, Container, rem } from "@mantine/core";

/**
 * Component to display the start game container button
 * @param {object} props.room - Room data
 * @returns
 */
const StopButtonContainer = ({ disabled = false, onClick }) => {
	return (
		<Container
			mt={20}
			p="sm"
			bg="var(--mantine-color-dark-6)"
			shadow="xl"
			radius="xl"
			style={{ width: "100%", borderRadius: rem(20) }}
		>
			<Button
				variant="filled"
				radius="lg"
				size="sm"
				disabled={disabled}
				styles={{
					root: {
						width: "100%",
						height: rem(48)
					}
				}}
				color="red"
				onClick={onClick}
			>
				STOP
			</Button>
		</Container>
	);
};

export default StopButtonContainer;
