import { leaveRoom } from 'store/reducers/roomReducer';
import { logout } from 'store/reducers/userReducer';
import { wsEvent } from './wsActions';

export const leaveRoomAndLogout =
  (refresh = false) =>
  (dispatch, getState) => {
    let userUUID = getState().user.uuid;

    dispatch(leaveRoom());
    dispatch(logout());

    // Disconnect user of backend
    dispatch(
      wsEvent({
        event: 'disconnect_user',
        payload: {
          userId: userUUID,
        },
      })
    );

    if (refresh) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };
