import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "store/reducers/userReducer";
import { useNavigate } from "react-router-dom";
import { UnstyledButton, Group, Avatar, Text, rem, Menu } from "@mantine/core";
import {
	IconChevronRight,
	IconLogout,
	IconMoodHappy,
	IconSettings,
	IconUser
} from "@tabler/icons-react";
import classes from "./UserButton.module.css";
import { leaveRoomAndLogout } from "store/actions/userActions";
import UserCard from "components/UserCard";

export function UserButton() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = useSelector(selectUser);

	const goToLogin = () => [navigate("/")];

	const logoutUser = () => {
		dispatch(leaveRoomAndLogout());
		navigate("/");
	};

	if (!user.loggedIn) {
		return (
			<UnstyledButton className={classes.user} onClick={goToLogin}>
				<Group>
					<Avatar radius="xl" color="red">
						<IconUser />
					</Avatar>

					<div style={{ flex: 1 }}>
						<Text size="sm" fw={500}>
							Déconnecté
						</Text>
					</div>
				</Group>
			</UnstyledButton>
		);
	} else {
		return (
			<Menu shadow="md" width={200} position="right">
				<Menu.Target>
					<UnstyledButton className={classes.user}>
						<Group>
							<UserCard user={user} />
							<IconChevronRight style={{ width: rem(14), height: rem(14) }} stroke={1.5} />
						</Group>
					</UnstyledButton>
				</Menu.Target>
				<Menu.Dropdown>
					<Menu.Label>{user.username}</Menu.Label>
					<Menu.Item
						disabled
						leftSection={<IconMoodHappy style={{ width: rem(14), height: rem(14) }} />}
					>
						Profil
					</Menu.Item>
					<Menu.Item
						disabled
						leftSection={<IconSettings style={{ width: rem(14), height: rem(14) }} />}
					>
						Paramètres
					</Menu.Item>

					<Menu.Divider />

					<Menu.Label>Actions</Menu.Label>
					<Menu.Item
						color="red"
						leftSection={<IconLogout style={{ width: rem(14), height: rem(14) }} />}
						onClick={logoutUser}
					>
						Se déconnecter
					</Menu.Item>
				</Menu.Dropdown>
			</Menu>
		);
	}
}
