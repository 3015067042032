import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clientsCount: 0,
  wsEstablished: false,
};

export const slice = createSlice({
  name: "server",
  initialState,

  reducers: {
    setClientsCount: (state, action) => {
      return {
        ...state,
        clientsCount: action.payload,
      };
    },
    setWSConnection: (state, action) => {
      return {
        ...state,
        wsEstablished: action.payload,
      };
    },
  },
});

export const getClientsCount = (state) => state.server.clientsCount;
export const getWSConnection = (state) => state.server.wsEstablished;

export const { setClientsCount, setWSConnection } = slice.actions;

export default slice.reducer;
