import { Button, Tooltip, rem } from "@mantine/core";
import { IconDoorExit } from "@tabler/icons-react";

export function LeaveButton({ onClick }) {
  return (
    <Tooltip
      label="Quitter la partie"
      position="left"
      withArrow
      transition="fade"
      duration={200}
      zIndex={1000}
      bg={"var(--mantine-color-dark-1)"}
    >
      <Button
        variant="light"
        // rightSection={
        //   <IconDoorExit
        //     style={{ width: rem(20), height: rem(20) }}
        //     stroke={1.5}
        //   />
        // }
        radius="xl"
        size="md"
        styles={{
          root: { height: rem(48) },
          // section: { marginLeft: rem(22) },
        }}
        color="red"
        onClick={onClick}
      >
        <IconDoorExit
          style={{ width: rem(20), height: rem(20) }}
          stroke={1.5}
        />
        {/* Quitter */}
      </Button>
    </Tooltip>
  );
}
