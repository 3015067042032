import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import persistCombineReducers from "redux-persist/es/persistCombineReducers";
import rootReducer from "./reducers";
import persistStore from "redux-persist/es/persistStore";
import { websocketMiddleware } from "./middleware/wsMiddleware";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistCombineReducers(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    });
    middlewares.push(websocketMiddleware);
    return middlewares;
  },
});

export const persistor = persistStore(store);
