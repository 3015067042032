import {
	Avatar,
	Button,
	Card,
	Container,
	Group,
	SimpleGrid,
	Text,
	getGradient,
	rem,
	useMantineTheme
} from "@mantine/core";
import { IconBrandAppleArcade, IconFlame, IconUser } from "@tabler/icons-react";
import classes from "./Style.module.css";
import { useNavigate } from "react-router-dom";
import { BadgeGallery } from "components/Badge";

const PRIMARY_COL_HEIGHT = rem(300);
/**
 * Card component for the play game section
 */
const PlayGameCard = ({ icon, title, description, onClick }) => {
	const theme = useMantineTheme();
	const background = getGradient({ deg: 40, from: "dark.4", to: "dark.6" }, theme);

	return (
		<Card
			withBorder
			radius="md"
			padding="xl"
			// bg="var(--mantine-color-body)"

			bg={background}
			style={{
				height: "auto"
			}}
		>
			<Group>
				{icon}
				<Group gap={0} flex={1}>
					<Text fz="lg" fw={800}>
						{title}
					</Text>
					<Text fz="lg" fw={300}>
						{description}
					</Text>
				</Group>
			</Group>
			<Group justify="end" mt={10}>
				<Button color="blue" size="md" radius="md" onClick={onClick}>
					Jouer
				</Button>
			</Group>
		</Card>
	);
};

const CountStatCard = ({ icon, title, value, style }) => {
	const theme = useMantineTheme();
	const background = getGradient({ deg: 40, from: "dark.4", to: "dark.6" }, theme);

	return (
		<Card withBorder radius="md" bg={background} style={style} className={classes.stat}>
			{icon}
			<div>
				<Text className={classes.label}>{title}</Text>
				<Text fz="xs" className={classes.count}>
					<span className={classes.value}> {value}</span>
				</Text>
			</div>
		</Card>
	);
};

export default function ConnectedHome({ user }) {
	const theme = useMantineTheme();
	const background = getGradient({ deg: 40, from: "dark.4", to: "dark.6" }, theme);

	const navigate = useNavigate();
	const SECONDARY_COL_HEIGHT = `calc(${PRIMARY_COL_HEIGHT} / 2 - var(--mantine-spacing-md) / 2)`;

	const XP_LEVEL = 300;

	const USER_LEVEL = Math.floor(user.xp / XP_LEVEL);
	const XP_PROGRESS = (user.xp % XP_LEVEL) / XP_LEVEL;
	const XP_PROGRESS_PERCENT = Math.floor(XP_PROGRESS * 100);
	const ACTUAL_LEVEL_XP = user.xp % XP_LEVEL;

	const getCreatedAt = () => {
		if (user.type === "guest") {
			return "Utilisateur invité";
		} else {
			return `Membre depuis le ${new Date(user.createdAt).toLocaleDateString()}`;
		}
	};

	return (
		<Container my="md" p={0}>
			{/* Play */}
			<Container m={0} p={0} mb={50}>
				<h1>Jouer</h1>
				<Container my="md" p={0}>
					<SimpleGrid cols={{ base: 1, sm: 2 }} spacing="md">
						<PlayGameCard
							icon={
								<IconBrandAppleArcade
									style={{
										width: rem(32),
										height: rem(32),
										color: `var(--mantine-color-blue-6)`
									}}
									stroke={1.5}
								/>
							}
							title="Partie classique"
							description="Jouer une partie classique avec vos amis"
							onClick={() => {
								navigate("/play");
							}}
						/>

						<PlayGameCard
							icon={
								<IconFlame
									style={{
										width: rem(32),
										height: rem(32),
										color: `var(--mantine-color-red-6)`
									}}
									stroke={1.5}
								/>
							}
							title="Partie flash"
							description="Chaque jour 10 nouveaux mots à trouver"
							onClick={() => {
								navigate("/play/flash");
							}}
						/>
					</SimpleGrid>
				</Container>
			</Container>
			{/* Progress */}
			<Container m={0} p={0}>
				<h1>Résumé de votre progression</h1>
				{/* Grid 1 */}
				<Container my="md" p={0}>
					{/* Hide right pannels */}
					{/* <SimpleGrid cols={{ base: 1, sm: user.type === 'client' ? 2 : 1 }} spacing="md"> */}
					<SimpleGrid cols={{ base: 1, sm: 1 }} spacing="md">
						{/* Left */}
						<Card withBorder radius="md" padding="xl" bg={background}>
							<Group>
								<Avatar radius="xl" color="indigo" src={user.avatar}>
									{!user.avatar && <IconUser />}
								</Avatar>

								<div style={{ flex: 1 }}>
									<Text size="sm" fw={500}>
										{user.username}
									</Text>
								</div>

								<Text className={classes.label}>{getCreatedAt()}</Text>
							</Group>
							<Container mt={30} />
							<Text
								className={classes.label}
								style={{
									textAlign: "center"
								}}
								mb={50}
							>
								Les badges ne sont pas encore disponibles, ils le seront prochainement
							</Text>
							<BadgeGallery user={user} />
						</Card>
						{/* Right */}
					</SimpleGrid>
				</Container>
				{/* Other grids here */}
			</Container>
		</Container>
	);
}
