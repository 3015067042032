import { notifications } from "@mantine/notifications";
import "./flash.style.css";
import {
	Accordion,
	Button,
	Card,
	Container,
	Flex,
	Group,
	Table,
	Text,
	Title,
	rem
} from "@mantine/core";
import { IconArrowRight, IconClockHour4, IconMedal, IconShare, IconX } from "@tabler/icons-react";
import { useRef } from "react";

const FlashEndComponent = ({ uuid, game }) => {
	const elementRef = useRef(null);

	const computeDuration = () => {
		let start = game.startTS;
		let now = game.endTS;
		let duration = now - start;
		let humanDisplay = new Date(duration).toISOString().substr(11, 8);
		return humanDisplay;
	};

	const formatTime = time => {
		const date = new Date(time);
		const minutes = date.getMinutes().toString().padStart(2, "0");
		const seconds = date.getSeconds().toString().padStart(2, "0");
		return `${minutes}m ${seconds}s`;
	};

	const addIntSymbol = number => {
		if (number >= 0) {
			return `+${number}`;
		} else {
			return number;
		}
	};

	const shareResult = () => {
		let time = formatTime(game.endTS - game.startTS);

		let text = `Petit Bac Flash ${new Date().toLocaleDateString()} : ${
			game.score
		} Pts. en ${time} 🎉\n\n`;
		// text += "```";
		for (let theme of game.themes) {
			let score = computeScore(theme.theme);

			let themeHistory = game.history[theme.theme];
			let lastWord = themeHistory[themeHistory.length - 1];

			let textWord = "";
			if (lastWord.type === "skip") {
				textWord = "⬛❌⬛";
			} else {
				textWord = "⬛✅⬛";
			}

			text += `${theme.emoji}${textWord} ${addIntSymbol(score)} Pts. ${
				lastWord.type === "skip" ? "(Thème passé)" : ""
			}\n`;
		}
		text += `\n(Pénalité de temps de ${game.timePenalty} points).\n`;
		// text += "```";
		text += `\nEssaie de me battre sur https://${window.location.host}/flash`;

		navigator.clipboard.writeText(text);

		notifications.show({
			loading: false,
			title: "Résultat copié dans le presse papier !",
			message: "Coller le presse papier n'importe ou pour le partager",
			autoClose: true,
			withCloseButton: true
		});
	};

	const computeScore = theme => {
		let history = game.history[theme];
		if (!theme) {
			return 0;
		}
		let score = history.reduce((acc, item) => acc + item.score, 0);
		return score;
	};

	const color = correct => {
		if (correct) {
			return "var(--mantine-color-green-5";
		}
		return "var(--mantine-color-red-5";
	};

	const getThemeHistory = theme => {
		let history = game.history[theme];
		if (!theme) {
			return "Aucun";
		}

		return (
			<Table>
				<Table.Tbody>
					{history.map((item, index) => {
						if (item.type === "skip") {
							return (
								<Table.Tr key={index}>
									<Table.Th>
										<Flex>
											<IconX color="var(--mantine-color-red-5)" />
											<Text
												ml="sm"
												style={{
													fontStyle: "italic"
												}}
											>
												Thème passé
											</Text>
										</Flex>
									</Table.Th>
									<Table.Th>
										<Text color={color(false)}>{item.score}</Text>
									</Table.Th>
								</Table.Tr>
							);
						}
						return (
							<Table.Tr key={index}>
								<Table.Th>
									<Flex>
										<IconArrowRight color={color(item.correct)} />
										<Text ml="sm">{item.word}</Text>
									</Flex>
								</Table.Th>
								<Table.Th>
									<Text color={color(item.correct)}>{item.score}</Text>
								</Table.Th>
							</Table.Tr>
						);
					})}
					<Table.Tr>
						<Table.Th>Total</Table.Th>
						<Table.Th>{computeScore(theme)}</Table.Th>
					</Table.Tr>
				</Table.Tbody>
			</Table>
		);
	};

	return (
		<Container
			m={0}
			p={0}
			fluid
			flex="1"
			display="flex"
			style={{
				flexDirection: "column"
			}}
		>
			{/* Line header */}
			<Flex>
				<Card flex="1" radius="md" bg="var(--mantine-color-dark-5)">
					<Flex align="center" justify="space-evenly">
						{/* Temps écoulé */}
						<Flex direction="column" m={10}>
							<Group align="center" gap={0}>
								<IconClockHour4
									style={{
										color: `var(--mantine-color-indigo-6)`
									}}
									stroke={1.5}
								/>
								<Text ml={5} fw={600}>
									Temps total
								</Text>
							</Group>
							<Text align="center" fw={900}>
								{computeDuration()}
							</Text>
						</Flex>
						{/* Themes */}
						{/* <Flex direction="column" m={10}>
              <Group align="center" gap={0}>
                <IconFolder
                  style={{
                    color: `var(--mantine-color-green-6)`,
                  }}
                  stroke={1.5}
                />
                <Text ml={5} fw={600}>
                  Thèmes
                </Text>
              </Group>
              <Text align="center" fw={900}>
                {`${game.wordIndex}/10`}
              </Text>
            </Flex> */}
						{/* Penalty */}
						<Flex direction="column" m={10}>
							<Group align="center" gap={0}>
								<IconMedal
									style={{
										color: `var(--mantine-color-red-6)`
									}}
									stroke={1.5}
								/>
								<Text ml={5} fw={600}>
									Pénalité de temps
								</Text>
							</Group>
							<Text align="center" fw={900}>
								{`${game.timePenalty} Points`}
							</Text>
						</Flex>
						{/* Points */}
						<Flex direction="column" m={10}>
							<Group align="center" gap={0}>
								<IconMedal
									style={{
										color: `var(--mantine-color-yellow-6)`
									}}
									stroke={1.5}
								/>
								<Text ml={5} fw={600}>
									Points
								</Text>
							</Group>
							<Text align="center" fw={900}>
								{game.score}
							</Text>
						</Flex>
					</Flex>
				</Card>
			</Flex>
			{/* Summary copy paste */}
			<Flex mt="xl" direction="column">
				<Flex justify="space-between" align="center">
					<Title order={3}>Résumé</Title>
					<Button
						variant="light"
						rightSection={<IconShare style={{ width: rem(20), height: rem(20) }} stroke={1.5} />}
						radius="xl"
						size="md"
						color="indigo"
						onClick={shareResult}
					>
						Partager mon résultat
					</Button>
				</Flex>
				<Card mt="sm" flex="1" radius="md" bg="var(--mantine-color-dark-5)" ref={elementRef}>
					<Table>
						<Table.Thead>
							<Table.Tr>
								<Table.Th>Thème</Table.Th>
								<Table.Th>Lettre</Table.Th>
								<Table.Th>Score</Table.Th>
							</Table.Tr>
						</Table.Thead>
						<Table.Tbody>
							{game.themes.map(({ theme, emoji, letter, label }, index) => {
								return (
									<Table.Tr key={index}>
										<Table.Td>{`${emoji} ${label}`}</Table.Td>
										<Table.Td>{letter}</Table.Td>
										<Table.Td
											style={{
												color: color(Boolean(computeScore(theme) > 0))
											}}
										>
											{computeScore(theme)}
										</Table.Td>
									</Table.Tr>
								);
							})}
							{/* Time penalty */}
							<Table.Tr
								style={{
									border: "none"
								}}
							>
								<Table.Td></Table.Td>
							</Table.Tr>
							<Table.Tr>
								<Table.Td>Pénalité de temps</Table.Td>
								<Table.Td></Table.Td>
								<Table.Td
									style={{
										color: "var(--mantine-color-red-5"
									}}
								>
									{`-${game.timePenalty}`}
								</Table.Td>
							</Table.Tr>
						</Table.Tbody>
					</Table>
				</Card>
			</Flex>
			{/* History */}
			<Flex mt="xl" direction="column">
				<Title order={3}>Historique</Title>
				<Accordion mt="sm" radius="md" chevronPosition="right" variant="contained">
					{game.themes.map(({ theme, emoji, letter, label }, index) => {
						return (
							<Accordion.Item value={theme} key={index}>
								<Accordion.Control>
									<Group wrap="nowrap">
										<Text>{emoji}</Text>
										<div>
											<Text>{label}</Text>
											<Text size="sm" c="dimmed" fw={400}>
												{`${(game.history[theme] ?? []).length} réponses`}
											</Text>
										</div>
									</Group>
								</Accordion.Control>
								<Accordion.Panel>{getThemeHistory(theme)}</Accordion.Panel>
							</Accordion.Item>
						);
					})}
				</Accordion>
			</Flex>
		</Container>
	);
};

export default FlashEndComponent;
