/* eslint-disable react-hooks/exhaustive-deps */
import "./style.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { wsEvent } from "store/actions/wsActions";
import { selectUser } from "store/reducers/userReducer";
import { socket } from "store/middleware/wsMiddleware";
import { Box, Center, Container, Flex, Group, Paper, Text, Title } from "@mantine/core";
import { IconAwardFilled } from "@tabler/icons-react";
import { LeaveButton } from "components/LeaveButton";
import ReplayButtonContainer from "./components/ReplayButtonContainer";
import UserCard from "components/UserCard";

/**
 * Classic game results screen
 */
export default function ClassicScreenResults() {
	const room = useSelector(state => state.room);
	const config = room?.config ?? {};

	const dispatch = useDispatch();
	const user = useSelector(selectUser);

	const [sortedScoreUser, setSortedScoreUser] = useState([]);

	const leaveRoomAction = (uuid = user.uuid) => {
		dispatch(
			wsEvent({
				event: "user_leave_room",
				payload: {
					roomId: room.roomId,
					userId: uuid
				}
			})
		);
	};

	const replayGame = () => {
		socket.emit("game_classic_replay_game", { roomId: room.roomId });
	};

	const isFounder = () => {
		let roomUser = room.users.find(u => u.uuid === user.uuid);
		return roomUser?.founder ?? false;
	};

	useEffect(() => {
		const filteredScoreUser = [];
		const score = config.game.score;
		// Sort the score object by score
		for (const [key, value] of Object.entries(score)) {
			filteredScoreUser.push({ uuid: key, score: value });
		}
		filteredScoreUser.sort((a, b) => b.score - a.score);
		// Add every user to the filteredScoreUser array that are not in the score object
		room.users.forEach(user => {
			if (!filteredScoreUser.find(scoreUser => scoreUser.uuid === user.uuid)) {
				filteredScoreUser.push({ uuid: user.uuid, score: 0 });
			}
		});
		setSortedScoreUser(filteredScoreUser);
	}, []);

	return (
		<Container
			m={0}
			p={0}
			fluid
			flex="1"
			style={{
				position: "relative",
				height: "calc(100vh - 2rem)",
				display: "flex",
				flexDirection: "column"
			}}
		>
			<Paper shadow="lg" radius="xl" p="md" bg="var(--mantine-color-dark-6)">
				<Flex direction="row" align="center" justify="space-between">
					<Flex flex={1} align="center"></Flex>

					<Flex flex={1} align="center" justify="center">
						<Title order={2}>Fin de la partie !</Title>
					</Flex>

					<Flex flex={1} justify="end">
						<LeaveButton onClick={() => leaveRoomAction()} />
					</Flex>
				</Flex>
			</Paper>

			<Flex style={{ flex: 1, height: "100%", overflow: "auto" }} direction="column">
				<Box
					mt={40}
					flex={1}
					style={{
						height: "100%",
						overflow: "auto"
					}}
				>
					<Center>
						<Flex style={{ width: "60%" }} direction="column">
							{sortedScoreUser.map((user, index) => {
								const roomUser = room.users.find(roomUser => roomUser.uuid === user.uuid);
								if (!roomUser) return null;
								return (
									<Paper
										key={roomUser.uuid}
										shadow="lg"
										radius="lg"
										p="md"
										bg="var(--mantine-color-dark-6)"
										style={{
											width: "100%"
										}}
										flex="1"
										mt={10}
									>
										<Group
											style={{
												width: "100%"
											}}
											flex="1"
										>
											<Flex align="center">
												<Text size="xl" fw={900}>
													{`${index + 1} -`}
												</Text>
											</Flex>
											<UserCard user={roomUser} displayServerStatus={false} displayXpBar={false} />

											<Flex align="center">
												<Text m={0} fw={900} size="xl" mr={5}>
													{user.score}
												</Text>
												<IconAwardFilled m={0} size={20} />
											</Flex>
										</Group>
									</Paper>
								);
							})}
						</Flex>
					</Center>
				</Box>

				<Flex style={{}} direction="row" mt={10} justify="end">
					<Box
						style={{
							width: "35%"
						}}
					>
						<ReplayButtonContainer isFounder={isFounder} onClick={replayGame} />
					</Box>
				</Flex>
			</Flex>
		</Container>
	);
}
