import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  badges: [],
};

export const slice = createSlice({
  name: 'server',
  initialState,

  reducers: {
    setBadges: (state, action) => {
      return {
        ...state,
        badges: action.payload,
      };
    },
  },
});

export const getBadges = (state) => state.badge.badges;

export const { setBadges } = slice.actions;

export default slice.reducer;
